// import React, { useEffect, useState } from "react";
// import { IoIosArrowDown } from "react-icons/io";
// import { IoIosArrowUp } from "react-icons/io";
// import { AiOutlineEdit } from "react-icons/ai";
// const ViewTimeline = ({ user }) => {
//   const [timelineData, setTimelineData] = useState([]);
//   const [visibleItems, setVisibleItems] = useState(3);
//   const [hasMore, setHasMore] = useState(true);
//   const [expandedItems, setExpandedItems] = useState({});
//  const [GetAtlysPopulateMeeting,setGetAtlysPopulateMeeting]=useState({})
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_BASE_URL}/GetEntityManagementbyID/${user}`
//         );
//         const data = await response.json();
//         setTimelineData(data.GetProgressBar2);

//         console.log("GetEntityManagementbyID", data);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     if (user) {
//       fetchData();
//     }
//   }, [user]);

//   const handleShowMore = () => {
//     setVisibleItems((prev) => {
//       const newVisibleItems = prev + 3;
//       if (newVisibleItems >= timelineData.length) {
//         setHasMore(false);
//         return timelineData.length;
//       }
//       return newVisibleItems;
//     });
//   };

//   const toggleExpand = (index) => {
//     setExpandedItems((prev) => ({
//       ...prev,
//       [index]: !prev[index],
//     }));
//   };

//   const handleEdit =()=>{

//   }
//   return (
//     <div className="flex flex-col items-start space-y-8 p-4 md:p-4 lg:p-8">
//       {timelineData.slice(0, visibleItems).map((item, index) => (
//         <div key={index} className="relative flex items-start w-full">
//           {/* Vertical Line */}
//           {index < timelineData.slice(0, visibleItems).length - 1 && (
//             <div className="absolute left-2.5 top-12 bottom-0 w-1 bg-gray-300 z-0 rounded-lg"></div>
//           )}
//           <div className="flex-shrink-0 w-6 h-6 bg-blue-600 rounded-full flex items-center justify-center text-white relative z-10">
//             <span className="text-sm">●</span>
//           </div>

//           <div className="w-full">
//             <div className="ml-4 md:ml-10 bg-white p-4 rounded-lg shadow-md border w-full flex flex-col md:flex-row justify-between">
//               <div className="flex-1">
//                 <h3 className="text-xs font-bold">{item.Title}</h3>
//                 <h4 className="text-sm font-semibold text-gray-600 flex flex-col md:flex-row md:items-center mt-1">
//                   <span>{item.Type}</span>
//                   <p className="text-sm px-1">
//                     ({item.Date} : {item.Time})
//                   </p>
//                 </h4>
//                 <p className="text-sm mt-2">
//                   <strong>Note:</strong> {item.Note}
//                 </p>
//                 {expandedItems[index] && (
//                   <div className="mt-2">
//                     <p className="text-sm mt-1">
//                       <strong>Venue:</strong> {item.Venue || "N/A"}
//                     </p>
//                     <p className="text-sm mt-1">
//                       <strong>Reminder Date:</strong> {item.ReminderDate}
//                     </p>
//                     <p className="text-sm mt-1">
//                       <strong>Email ID:</strong> {item.EmailID}
//                     </p>
//                   </div>
//                 )}
//               </div>
//               <div className="mt-2 md:mt-0 flex items-center gap-3">
//                 {item.Type === "Meeting" && (
//                   <button
//                     onClick={handleEdit}
//                     className="text-green-400 hover:text-green-700 flex items-center text-center pointer-events-auto"
//                   >
//                     <AiOutlineEdit className="h-6 w-6" />
//                   </button>
//                 )}
//                 <button
//                   onClick={() => toggleExpand(index)}
//                   className="px-1 py-1 text-black rounded-full bg-slate-300 hover:bg-slate-100"
//                 >
//                   {expandedItems[index] ? <IoIosArrowUp /> : <IoIosArrowDown />}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}
//       {hasMore && (
//         <button
//           onClick={handleShowMore}
//           className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
//         >
//           Show More
//         </button>
//       )}
//     </div>
//   );
// };

// export default ViewTimeline;

import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { AiOutlineEdit } from "react-icons/ai";
import CreateMeeting from "../MasterFrom/CreateMeeting";
import TitleCard from "../Card/TitleCard";

const ViewTimeline = ({ user }) => {
  const [timelineData, setTimelineData] = useState([]);
  const [visibleItems, setVisibleItems] = useState(3);
  const [hasMore, setHasMore] = useState(true);
  const [expandedItems, setExpandedItems] = useState({});
  const [meetingData, setMeetingData] = useState(0);
  // const user = location.state?.user;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/GetEntityManagementbyID/${user}`
        );
        const data = await response.json();
        setTimelineData(data.GetProgressBar2);

        console.log("GetEntityManagementbyID", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (user) {
      fetchData();
    }
  }, [user]);
  console.log("data user time", user);
  const handleShowMore = () => {
    setVisibleItems((prev) => {
      const newVisibleItems = prev + 3;
      if (newVisibleItems >= timelineData.length) {
        setHasMore(false);
        return timelineData.length;
      }
      return newVisibleItems;
    });
  };

  const toggleExpand = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleEdit = async (sysID) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/GetAtlysPopulateMeeting/${sysID}`
      );
      const data = await response.json();
      setMeetingData(data); // Store the meeting data for use
      console.log("Fetched meeting data:", data);
    } catch (error) {
      console.error("Error fetching meeting data:", error);
    }
  };

  return (
    <>
      {meetingData ? (
        <CreateMeeting user={user} meetingData={meetingData[0]} />
      ) : (
        <TitleCard title={"View Timeline"}>
          <div className="flex flex-col items-start space-y-8 p-4 md:p-4 lg:p-8">
            {timelineData.slice(0, visibleItems).map((item, index) => (
              <div key={index} className="relative flex items-start w-full">
                {/* Vertical Line */}
                {index < timelineData.slice(0, visibleItems).length - 1 && (
                  <div className="absolute left-2.5 top-12 bottom-0 w-1 bg-gray-300 z-0 rounded-lg"></div>
                )}
                <div className="flex-shrink-0 w-6 h-6 bg-blue-600 rounded-full flex items-center justify-center text-white relative z-10">
                  <span className="text-sm">●</span>
                </div>

                <div className="w-full">
                  <div className="ml-4 md:ml-10 bg-white p-4 rounded-lg shadow-md border w-full flex flex-col md:flex-row justify-between">
                    <div className="flex-1">
                      <h3 className="text-xs font-bold">{item.Title}</h3>
                      <h4 className="text-sm font-semibold text-gray-600 flex flex-col md:flex-row md:items-center mt-1">
                        <span>{item.Type}</span>
                        <p className="text-xs md:text-sm md:px-1">
                          ({item.Date} : {item.Time})
                        </p>
                      </h4>
                      <p className="text-sm mt-2">
                        <strong>Note:</strong> {item.Note}
                      </p>
                      {expandedItems[index] && (
                        <div className="mt-2">
                          {/* <p className="text-sm mt-1">
                      <strong>Venue:</strong> {item.Venue || "N/A"}
                    </p> */}
                          <p className="text-sm mt-1">
                            <strong>Reminder Date:</strong> {item.ReminderDate}
                          </p>
                          {/* <p className="text-sm mt-1">
                      <strong>Email ID:</strong> {item.EmailID}
                    </p> */}
                        </div>
                      )}
                    </div>
                    <div className="mt-2 md:mt-0 flex items-center gap-3">
                      {item.Type === "Meeting" && (
                        <button
                          onClick={() => handleEdit(item.SysID)} // Pass SysID to handleEdit
                          className="text-green-400 hover:text-green-700 flex items-center text-center pointer-events-auto"
                        >
                          <AiOutlineEdit className="h-6 w-6" />
                        </button>
                      )}
                      <button
                        onClick={() => toggleExpand(index)}
                        className="px-1 py-1 text-black rounded-full bg-slate-300 hover:bg-slate-100"
                      >
                        {expandedItems[index] ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {hasMore && (
              <button
                onClick={handleShowMore}
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Show More
              </button>
            )}
          </div>
        </TitleCard>
      )}
    </>
  );
};

export default ViewTimeline;
