import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { decode } from "../utils";
import Avatar from "../components/Avatar";
import TitleCard from "./TitleCard";
import CreateMeeting from "../MasterFrom/CreateMeeting";
import NewNotesReminder from "../MasterFrom/NewNotesReminder";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import ViewTimeline from "../components/ViewTimeine";
import { MdModeEdit } from "react-icons/md";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import axios from "axios";
import EntityMaster from "../Pages/Entitymaster";
import EntityMasterForm from "../MasterFrom/EntityMasterForm";
const LeadPreview = () => {
  const [SelectedRowData, setSelectedRowData] = useState();
  const [Activity, setActivity] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(null); // Tracks which section is active
  const user = location.state?.user;
  const [newdata,setnewdata]=useState(false);


  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };


  const handleToggleSection = (section) => {
    setnewdata(!newdata);
    setActiveSection((prevSection) =>
      prevSection === section ? null : section
    );
  };


  useEffect(() => {
    if (user.EntityID) {
      handleEdit();
    }
  }, [newdata]);

  const handleEdit = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/GetAtlysPopulateEnityMaster/${user.EntityID}`
      );

      if (response.status === 200) {
        if (response.data) {
          setSelectedRowData(response.data[0]);
         
        } else {
          console.error("Unexpected response format:", response);
        }
      } else {
        console.error("Error fetching data:", response.status);
      }
    } catch (err) {
      console.error("Error occurred while fetching entity data:", err);
    }

  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/GetEntityManagementbyID/${user}`
        );
        const data = await response.json();
        setActivity(data.GetProgressBar1[0]);

        console.log("Fetched data:", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (user) {
      fetchData();
    }
  }, [user]);
  
  const handleEditButton=()=>{
    handleToggleSection("Entitymasterfrom");
    handleEdit();
  }

  return (
    <div className="container mx-auto px-2">
      {/* Header Section */}
      <div className="flex flex-col md:flex-row justify-between mb-2">
        <button
          onClick={handleBackClick}
          className="flex text-gray-600  pt-4 text-2xl   hover:text-black  ease-out duration-300 "
        >
          <MdOutlineKeyboardBackspace />
        </button>
      </div>

      <div className="bg-white rounded-lg shadow-2xl p-4">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
          <div className="flex flex-col md:flex-row gap-5">
            <div className="flex-shrink-0 md:mb-0">
              <Avatar name={SelectedRowData?.Name || "User"} />
            </div>
            <div className="md:text-left">
              <h2 className="text-xl md:text-2xl font-bold text-gray-800">
                {SelectedRowData?.Name}
              </h2>
              <p className="text-gray-600 overflow-hidden text-sm">
                {SelectedRowData?.Email}
              </p>
            </div>
          </div>

          <div className="flex flex-wrap gap-2 mt-3">
            <button
              onClick={handleEditButton}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full inline-flex items-center"
            >
              <MdModeEdit />
            </button>
            <button className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full inline-flex items-center">
              <a href={`tel:${SelectedRowData?.Phone}`}>
                <FaPhoneAlt />
              </a>
            </button>
            <button className="bg-gray-200 hover:bg-gray-300  text-gray-800 font-bold py-2 px-4 rounded-full inline-flex items-center">
              <a href={`mailto:${SelectedRowData?.Email}`}>
                <FaEnvelope />
              </a>
            </button>
          </div>
        </div>

        {/* Details Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          <div>
            <p className="text-gray-600 font-semibold mb-1">Type:</p>
            <p className="text-gray-800 text-sm sm:text-base">
              {SelectedRowData?.Type}
            </p>
          </div>
          <div>
            <p className="text-gray-600 font-semibold mb-1">City:</p>
            <p className="text-gray-800 text-sm sm:text-base">
              {SelectedRowData?.City}
            </p>
          </div>
          <div>
            <p className="text-gray-600 font-semibold mb-1">Job Role:</p>
            <p className="text-gray-800 text-sm sm:text-base">
              {SelectedRowData?.Role}
            </p>
          </div>
          <div>
            <p className="text-gray-600 font-semibold mb-1">Country:</p>
            <p className="text-gray-800 text-sm sm:text-base">
              {SelectedRowData?.DestinationCountry}
            </p>
          </div>
        </div>
      </div>
      {activeSection === "Entitymasterfrom" && (
        <TitleCard>
          {" "}
          <EntityMasterForm
            changeForms={() => {
              handleToggleSection("Entitymasterfrom");
            }}
            selectedRowData={SelectedRowData}
          />
        </TitleCard>
      )}
      <div className="bg-white rounded-lg shadow-2xl p-4 sm:p-6 lg:p-8 my-4 sm:mt-6 lg:mt-8 ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <button
            onClick={() => handleToggleSection("meeting")}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full inline-flex items-center justify-center"
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <span className="text-xs sm:text-sm md:text-base">New Meeting</span>
          </button>
          <button
            onClick={() => handleToggleSection("note")}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full inline-flex items-center justify-center"
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <span className="text-xs sm:text-sm md:text-base">
              New Note/Reminder
            </span>
          </button>
          <button
            onClick={() => handleToggleSection("timeline")}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full inline-flex items-center justify-center"
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <span className="text-xs sm:text-sm md:text-base">
              View Timeline
            </span>
          </button>
        </div>

        <p className=" flex   text-gray-600 mt-2 sm:mt-4 md:mt-6 lg:mt-8 text-xs sm:text-sm md:text-base lg:text-lg">
          <p className="text-sm font-bold">Last Activity Date: </p>{" "}
          <p className="text-sm">{Activity?.LastActivityDate || "N/A"}</p>
        </p>

      </div>
      {activeSection === "timeline" && <ViewTimeline user={user} />}
      {activeSection === "meeting" && <CreateMeeting user={user} />}
      {activeSection === "note" && <NewNotesReminder user={user} />}
    </div>
  );
};

export default LeadPreview;
