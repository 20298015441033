import React, { useState } from "react";
import EntityMasterForm from "../MasterFrom/EntityMasterForm";
import EntityMastermain from "../Mastermains/EntityMastermain";
import TitleCard from "../Card/TitleCard";
import { useUser } from "../Context/UserContext";
import { useAuth } from "../Context/AuthContext";

function EntityMaster() {
  const { user } = useUser();
  const { userdata } = useAuth();
  const [showBilling, setShowBilling] = useState(true);
  const [selectedRowData, setSelectedRowData] = useState(null);
  console.log("CreatedBy user", userdata);

  const EmptData = {
    Name: "",
    DestinationCountry: "",
    Type: "",
    City: "",
    Role: "",
    BusinessCard: "",
    Phone: "",
    Email: "",
    IsActive: "",
    CreatedBy: userdata.UserID || "null", 
    ModifiedBy: userdata.UserID || "null", 
    LandLineNumber: "",
    DateOfBirth: "",
    IsMarried: "",
    DateOfAnniversary: "",
    GeographiesServed: "",
    RelationshipQuotient: "",
    TransferredCountry: "",
    Organization: "",
    Designation: "",
    AdditionalMobileNo: "",
    AdditionalContactNo: "",
    AdditionalEmailID: "",
    IsTransferred :0
  };

  // To Change form
  const changeForms = (rowData) => {
    setSelectedRowData(rowData ? rowData : EmptData);
    setShowBilling(!showBilling);
  };

  return (
    <TitleCard title={"Representatives"}>
      {showBilling ? (
        <EntityMastermain changeForms={changeForms} />
      ) : (
        <EntityMasterForm
          changeForms={changeForms}
          selectedRowData={selectedRowData}
        />
      )}
    </TitleCard>
  );
}

export default EntityMaster;
