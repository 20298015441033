import React, { useEffect, useState } from "react";
import InputText from "../Input/InputText";
import TextAreaInput from "../Card/TextAreaInput";
import Dropdown from "../Input/DropDown";
import axios from "axios";
import { message } from "antd";
import { VALIDATION_ERROR_MESSAGES, SUCCESS_MESSAGES , Messages} from "../message.js";
const OrganizationForm = ({ changeForms, selectedRowData }) => {
  const [inputdata, setInputData] = useState({ ...selectedRowData });
  const [typeDropdownData, setTypeDropdownData] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let url, method;

      if (selectedRowData && selectedRowData.OrgID) {
        url = `${process.env.REACT_APP_API_BASE_URL}/BindinsertOrganizationatlys/${selectedRowData.OrgID}`;
        method = `POST`;
      } else {
        url = `${process.env.REACT_APP_API_BASE_URL}/BindinsertOrganizationatlys/${selectedRowData.OrgID}`;
        method = `POST`;
      }

      const response = await axios({ 
        method: method,
        url: url,
        data: inputdata,
      });
      console.log("BindinsertOrganizationatlys", inputdata);
      if (selectedRowData && selectedRowData.OrgID) {
        message.success(Messages.Messages_Successfully_Edited);
      } else {
        message.success(Messages.Messages_Successfully);
        e.target.reset(); // Reset the form after successful submission
      }

      handleBack();
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      message.error(Messages.Messages_submit_error)
    }
  };

  const updateFormValue = (name, value) => {
    setInputData((prevData) => ({
      ...prevData,
      [name]: typeof value === "string" ? value : Boolean(value), // Ensure boolean value
    }));

    // Remove validation error when user starts typing
    // if (errors[name]) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     [name]: "",
    //   }));
    // }
  };

  useEffect(() => {
    fetchData();
    fetchTypeData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/GetBindDropdownForCountry`
      );
      setDropdownData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchTypeData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/commonmaster`
      );
      setTypeDropdownData(response.data);
    } catch (error) {
      console.error("Error fetching type data:", error);
    }
  };

  const handleBack = () => {
    changeForms();
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
    setInputData((prevData) => ({
      ...prevData,
      [name]: typeof value === "string" ? value : String(value),
    }));

    // if (errors[name]) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     [name]: "",
    //   }));
    // }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="grid gap-4 lg:grid-cols-2 md:grid-cols-1  my-4">
          <div className="gap-6">
            <div className="divider"></div>
            <Dropdown
              name="SourceCountry"
              labelTitle="Source Country "
              value={inputdata.SourceCountry}
              updateFormValue={updateFormValue}
              onChange={handleDropdownChange}
              options={dropdownData || []}
              labelText={"CountryName"}
              labelValue={"CountryID"}
              sup
            />
            <InputText
              name="City"
              labelTitle="City"
              defaultValue={inputdata.City}
              updateFormValue={updateFormValue}
            />
            <Dropdown
              name="ClientCountry"
              labelTitle="Client Country (Destination)"
              value={inputdata.ClientCountry}
              updateFormValue={updateFormValue}
              onChange={handleDropdownChange}
              options={dropdownData || []}
              labelText={"CountryName"}
              labelValue={"CountryID"}
              sup
            />
            {/* {errors.DestinationCountry && (
            <div className="text-red-500  text-sm ">
              {errors.DestinationCountry}
            </div>
          )} */}
            <Dropdown
              name="ClientType"
              labelTitle="Client Type"
              value={inputdata.ClientType}
              updateFormValue={updateFormValue}
              onChange={handleDropdownChange}
              options={typeDropdownData || []}
              labelText={"CMDescription"}
              labelValue={"CMDescription"}
              sup
            />
            <InputText
              name="Address"
              labelTitle="Address"
              defaultValue={inputdata.Address}
              updateFormValue={updateFormValue}
              sup
            />
            {/* {errors.Phone && (
              <div className="text-red-500  text-sm ">{errors.Phone}</div>
            )} */}
            <InputText
              type=""
              name="NationalDay"
              labelTitle="National Day "
              value={inputdata.NationalDay}
              updateFormValue={updateFormValue}
              sup
            />
            {/* {errors.Email && (
              <div className="text-red-500  text-sm ">{errors.Email}</div>
            )} */}
            <InputText
              type=""
              name="OtherRelevantDays"
              labelTitle="Any other relevant days"
              value={inputdata.OtherRelevantDays}
              updateFormValue={updateFormValue}
              sup
            />{" "}
            {/* {errors.DateOfBirth && (
              <div className="text-red-500  text-sm ">{errors.DateOfBirth}</div>
            )} */}
            <InputText
              type=""
              name="OtherRelevantDays1"
              labelTitle="Any other relevant days "
              Value={inputdata.OtherRelevantDays1}
              updateFormValue={updateFormValue}
            />
            <InputText
              type=""
              name="OtherRelevantDays2"
              labelTitle="Any other relevant days "
              Value={inputdata.OtherRelevantDays2}
              updateFormValue={updateFormValue}
            />
            <InputText
              type=""
              name="IsVisaSolutionsOutsourced"
              labelTitle="Visa solutions Outsourced "
              Value={inputdata.IsVisaSolutionsOutsourced}
              updateFormValue={updateFormValue}
            />
          </div>
          <div className="gap-6">
            <div className="divider"></div>

            <InputText
              type=""
              name="NameOfServiceProvider"
              labelTitle="If Yes - Mention the name of the Service Provider "
              Value={inputdata.NameOfServiceProvider}
              updateFormValue={updateFormValue}
            />

            <InputText
              type=""
              name="CurrentContractValidityPeriod"
              labelTitle="Current Contract Validity period "
              Value={inputdata.CurrentContractValidityPeriod}
              updateFormValue={updateFormValue}
            />

            <InputText
              type=""
              name="IsPossibilityToExtend"
              labelTitle="Possibility to extend"
              value={inputdata.IsPossibilityToExtend}
              updateFormValue={updateFormValue}
            />

            <InputText
              type=""
              name="ExtensionPeriod"
              labelTitle="Extension Period - How many years"
              defaultValue={inputdata.ExtensionPeriod}
              updateFormValue={updateFormValue}
              sup
            />
            {/* {errors.Role && (
              <div className="text-red-500  text-sm ">{errors.Role}</div>
            )} */}

            <InputText
              type=""
              name="IsOutsourcedViaTender"
              labelTitle="Outsourced via tender"
              value={inputdata.IsOutsourcedViaTender}
              updateFormValue={updateFormValue}
            />
            <InputText
              type=""
              name="TenderingThrough"
              labelTitle="If yes, tendering through"
              value={inputdata.TenderingThrough}
              updateFormValue={updateFormValue}
            />
            <InputText
              type=""
              name="Jurisdiction"
              labelTitle="Jurisdiction"
              value={inputdata.Jurisdiction}
              updateFormValue={updateFormValue}
            />
            <InputText
              type=""
              name="ApproxApplicantVolumes"
              labelTitle="Approx Applicant Volumes (annual)"
              value={inputdata.ApproxApplicantVolumes}
              updateFormValue={updateFormValue}
            />
            <InputText
              type=""
              name="IsScopeOfferSkylaneSolutions"
              labelTitle="Scope to offer Skylane solutions"
              value={inputdata.IsScopeOfferSkylaneSolutions}
              updateFormValue={updateFormValue}
            />

            {/* {errors.Type && (
              <div className="text-red-500  text-sm ">{errors.Type}</div>
            )} */}

            <InputText
              type=""
              name="TypeOfSolutionsOffered"
              labelTitle="Type of solutions to be offered"
              defaultValue={inputdata.TypeOfSolutionsOffered}
              updateFormValue={updateFormValue}
              sup
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between mt-4 gap-4">
          <button onClick={handleBack} className="btn w-full md:w-24">
            Back
          </button>
          <button
            type="submit"
            className="btn bg-gray-800 text-white hover:bg-neutral-400 border-none hover:text-black px-6 btn-sm normal-case md:ml-4"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrganizationForm;
