import React, { useState, useEffect } from "react";
import TitleCard from "../Card/TitleCard";
import Dropdown from "../Input/DropDown";
import InputText from "../Input/InputText";
import Avatar from "../components/Avatar";
import axios from "axios";
import { FaEye } from "react-icons/fa";
import Pagination from "../components/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../Context/AuthContext";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Optional: for default styling

const PageSize = 10;

const DashBoardPages = () => {
  const { userdata } = useAuth();
  const [data, setData] = useState([]);
  const [dashboardStats, setDashboardStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dropdown, setDropdownData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedemployee, setselectedemployee] = useState();
  const [fromDate, setFromDate] = useState(getOneWeekAgoDate());
  const [ToDate, setToDate] = useState(getDate());
  const [inputdata, setInputData] = useState({
    fromDate: fromDate,
    ToDate: ToDate,
  });

  const navigate = useNavigate();

  // const handleLeadPreview = (item) => {
  //   navigate("/home", {
  //     state: {
  //       UserName: item.UserName,
  //       fromDate: fromDate,
  //       ToDate: ToDate,
  //     },
  //   });
    
  // };
  const handleLeadPreview = (item) => {
    console.log('Item:', item);
    console.log('From Date:', inputdata.fromDate);
    console.log('To Date:', inputdata.ToDate);
  
    navigate("/home", {
      state: { 
        UserID: item.UserID,
        fromDate: inputdata.fromDate,
        ToDate: inputdata.ToDate
      } 
    });
  };
    



  useEffect(() => {
    // Fetch data from both APIs
    const fetchData = async () => {
      console.log("inputdata Dashboardpages:", inputdata);
      try {
        // Fetch dropdown data
        const strUserIDorNull = "00";
        if (userdata.IsSuperUser == "0"){
          strUserIDorNull = userdata.UserID
        }
        
        const dropdownResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/BindGridusermaster_get?UserID=${strUserIDorNull}`
        );
        setDropdownData(dropdownResponse.data);

        // Fetch dashboard data
        const dashboardResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/DashBoard?EmpID=${inputdata.EmpID}&fromDate=${inputdata.fromDate}&ToDate=${inputdata.ToDate}`,
          { inputdata } // send the input data as the request body
        );

        console.log("dashboardResponse :", dashboardResponse.data.summary);
        setData(dashboardResponse.data.details); // populate table data
        setDashboardStats(dashboardResponse.data.summary); // populate dashboard stats
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    console.log("data", data.summary);
    fetchData();
  }, [inputdata]);




 






  // console.log("inputdata", inputdata);
  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error}</p>;









  






  const updateFormValue = (name, value) => {
    if (name === "fromDate" || name === "ToDate") {
      const formattedDateValue = formatDateFromInput(value);
      if (name === "fromDate") {
        setFromDate(formattedDateValue);
        setInputData((prevData) => ({
          ...prevData,
          fromDate: formattedDateValue,
        }));
      } else if (name === "ToDate") {
        setToDate(formattedDateValue);
        setInputData((prevData) => ({
          ...prevData,
          ToDate: formattedDateValue,
        }));
      }
    } else {
      setInputData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    updateFormValue(name, value);
    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  function formatDateToInput(dateStr) {
    const [day, month, year] = dateStr.split("-");
    const monthIndex = new Date(`${month} 1, 2000`).getMonth() + 1;
    const monthFormatted = monthIndex.toString().padStart(2, "0");
    return `${year}-${monthFormatted}-${day}`;
  }

  function formatDateFromInput(dateStr) {
    const [year, month, day] = dateStr.split("-");
    const monthName = new Date(`${year}-${month}-${day}`)
      .toLocaleString("en-US", { month: "short" })
      .toLowerCase();
    return `${day}-${monthName}-${year}`;
  }

  function getDate() {
    const today = new Date();
    return formatDateFromInput(today.toISOString().split("T")[0]);
  }

  function getOneWeekAgoDate() {
    const today = new Date();
    today.setDate(today.getDate() - 7); // Subtract 7 days to get one week ago
    return formatDateFromInput(today.toISOString().split("T")[0]);
  }

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = data.slice(firstPageIndex, lastPageIndex);

  return (
    // <div className="my-4">
    //   <TitleCard>
    //     <div className="flex  justify-end">
    //       <Link to="/home" className="flex items-center ">
    //         <HomeIcon className="w-6 h-6 text-black" />
    //         {/* <span className="ml-3 text-black text-sm">Home</span> */}
    //       </Link>
    //     </div>
    //     <div className="flex flex-col md:flex-row justify-between  gap-4">
    //       <Dropdown
    //         name={"EmpID"}
    //         labelTitle={"Employee"}
    //         value={selectedemployee}
    //         options={dropdown || []}
    //         labelText={"UserName"}
    //         labelValue={"UserID"}
    //         onChange={handleDropdownChange}
    //         updateFormValue={updateFormValue}
    //       />

    //       <InputText
    //         name="fromDate"
    //         type={"date"}
    //         updateFormValue={updateFormValue}
    //         labelTitle={"From Date"}
    //         value={formatDateToInput(fromDate)}
    //       />
    //       <InputText
    //         name="ToDate"
    //         type={"date"}
    //         updateFormValue={updateFormValue}
    //         labelTitle={"To Date"}
    //         value={formatDateToInput(ToDate)}
    //       />

    //       {/* <button
    //         type="button"
    //         className="py-2.5 mt-7 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
    //         onClick={() => {
    //           setLoading(true);
    //           setCurrentPage(1);
    //         }}
    //       >
    //         Search
    //       </button> */}
    //     </div>

    //     <main className="py-6 space-y-12 w-full">
    //       <div className="flex flex-col h-full w-full mx-auto space-y-6">
    //         <section className="">
    //           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 w-full min-w-0">
    //             <div className="flex flex-col px-6 py-2 bg-white shadow-xl rounded-lg overflow-hidden">
    //               <div className="flex flex-col items-center space-y-2">
    //                 <div className="text-6xl font-bold tracking-tight leading-none text-blue-500">
    //                   {dashboardStats.TotalLeadCount}
    //                 </div>
    //                 <div className="text-sm font-medium text-blue-500">
    //                   Total Lead Count
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="flex flex-col px-6 py-2 bg-white shadow-xl rounded-lg overflow-hidden">
    //               <div className="flex flex-col items-center space-y-2">
    //                 <div className="text-6xl font-bold tracking-tight leading-none text-amber-500">
    //                   {dashboardStats.TotalMeetingCount}
    //                 </div>
    //                 <div className="text-sm font-medium text-amber-600">
    //                   Total Meeting Count
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="flex flex-col px-6 py-2 bg-white shadow-xl rounded-lg overflow-hidden">
    //               <div className="flex flex-col items-center space-y-2">
    //                 <div className="text-6xl font-bold tracking-tight leading-none text-red-500">
    //                   {dashboardStats.AvgLeadCount}
    //                 </div>
    //                 <div className="text-sm font-medium text-red-600">
    //                   Average Lead Count
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="flex flex-col px-6 py-2 bg-white shadow-xl rounded-lg overflow-hidden">
    //               <div className="flex flex-col items-center space-y-2">
    //                 <div className="text-6xl font-bold tracking-tight leading-none text-primary-900">
    //                   {dashboardStats.AvgMeetingCount}
    //                 </div>
    //                 <div className="text-sm font-medium text-primary-900">
    //                   Average Meeting Count
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </section>
    //       </div>
    //     </main>

    //     <section className="antialiased my-4">
    //       <div className="flex">
    //         <div className="w-full bg-white shadow-lg rounded-sm border border-gray-200">
    //           <header className="px-5 py-4 border-b border-gray-100">
    //             <h2 className="font-semibold text-gray-800">
    //               Dashboard Details
    //             </h2>
    //           </header>
    //           <div className="p-3">
    //             <div className="overflow-x-auto">
    //               <table className="table-auto w-full">
    //                 <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
    //                   <tr>
    //                     <th className="p-2 whitespace-nowrap">
    //                       <div className="font-semibold text-left">Name</div>
    //                     </th>
    //                     <th className="p-2 whitespace-nowrap">
    //                       <div className="font-semibold text-left">
    //                         ContactNo
    //                       </div>
    //                     </th>
    //                     <th className="p-2 whitespace-nowrap">
    //                       <div className="font-semibold text-left">
    //                         Lead Count
    //                       </div>
    //                     </th>
    //                     <th className="p-2 whitespace-nowrap">
    //                       <div className="font-semibold text-left">
    //                         Meeting Count
    //                       </div>
    //                     </th>
    //                     <th className="p-2 whitespace-nowrap">
    //                       <div className="font-semibold text-center">
    //                         Action
    //                       </div>
    //                     </th>
    //                   </tr>
    //                 </thead>
    //                 <tbody className="text-sm divide-y divide-gray-100">
    //                   {currentTableData.map((item) => (
    //                     <tr key={item.id}>
    //                       <td className="p-2 whitespace-nowrap flex items-center gap-2">
    //                         <Avatar name={item?.UserName || "User"} />
    //                         <div>
    //                           {item.UserName} <p>{item.EmailID}</p>
    //                         </div>
    //                       </td>
    //                       <td className="p-2 whitespace-nowrap">
    //                         {item.ContactNo}
    //                       </td>
    //                       <td className="p-2 whitespace-nowrap">
    //                         {item.LeadCount || 0}
    //                       </td>
    //                       <td className="p-2 whitespace-nowrap">
    //                         {item.MeetingCount || 0}
    //                       </td>
    //                       <td className="p-2 whitespace-nowrap text-center">
    //                         <button
    //                           className="text-blue-500"
    //                           onClick={() => handleLeadPreview(item)}
    //                         >
    //                           <FaEye />
    //                         </button>
    //                       </td>
    //                     </tr>
    //                   ))}
    //                 </tbody>
    //               </table>
    //               <Pagination
    //                 className="pagination-bar mt-4"
    //                 currentPage={currentPage}
    //                 totalCount={data.length}
    //                 pageSize={PageSize}
    //                 onPageChange={(page) => setCurrentPage(page)}
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   </TitleCard>
    // </div>


    <div className="">
    <TitleCard>
      <div className="flex justify-end">
        <Link to="/home" className="flex items-center">
          <HomeIcon className="w-6 h-6 text-black" />
        </Link>
      </div>
      <div className="flex flex-col md:flex-row justify-between gap-4">
        <Dropdown
          name={"EmpID"}
          labelTitle={"Employee"}
          value={selectedemployee}
          options={dropdown || []}
          labelText={"UserName"}
          labelValue={"UserID"}
          onChange={handleDropdownChange}
          updateFormValue={updateFormValue}
        />
        <InputText
          name="fromDate"
          type={"date"}
          updateFormValue={updateFormValue}
          labelTitle={"From Date"}
          value={formatDateToInput(fromDate)}
        />
        <InputText
          name="ToDate"
          type={"date"}
          updateFormValue={updateFormValue}
          labelTitle={"To Date"}
          value={formatDateToInput(ToDate)}
        />
      </div>

      <main className="py-6 space-y-12 w-full">
        <div className="flex flex-col h-full w-full mx-auto space-y-6">
          <section>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 w-full min-w-0">
              {loading ? (
                Array(4)
                  .fill()
                  .map((_, index) => (
                    <Skeleton key={index} height={100} className="rounded-lg" />
                  ))
              ) : (
                <>
                  <div className="flex flex-col px-6 py-2 bg-white shadow-xl rounded-lg overflow-hidden">
                    <div className="flex flex-col items-center space-y-2">
                      <div className="text-6xl font-bold tracking-tight leading-none text-blue-500">
                        {dashboardStats.TotalLeadCount}
                      </div>
                      <div className="text-sm font-medium text-blue-500">
                        Total Lead Count
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 py-2 bg-white shadow-xl rounded-lg overflow-hidden">
                    <div className="flex flex-col items-center space-y-2">
                      <div className="text-6xl font-bold tracking-tight leading-none text-amber-500">
                        {dashboardStats.TotalMeetingCount}
                      </div>
                      <div className="text-sm font-medium text-amber-600">
                        Total Meeting Count
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 py-2 bg-white shadow-xl rounded-lg overflow-hidden">
                    <div className="flex flex-col items-center space-y-2">
                      <div className="text-6xl font-bold tracking-tight leading-none text-red-500">
                        {dashboardStats.AvgLeadCount}
                      </div>
                      <div className="text-sm font-medium text-red-600">
                        Average Lead Count
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 py-2 bg-white shadow-xl rounded-lg overflow-hidden">
                    <div className="flex flex-col items-center space-y-2">
                      <div className="text-6xl font-bold tracking-tight leading-none text-primary-900">
                        {dashboardStats.AvgMeetingCount}
                      </div>
                      <div className="text-sm font-medium text-primary-900">
                        Average Meeting Count
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </main>

      <section className="antialiased my-4">
        <div className="flex">
          <div className="w-full bg-white shadow-lg rounded-sm border border-gray-200">
            <header className="px-5 py-4 border-b border-gray-100">
              <h2 className="font-semibold text-gray-800">
                Dashboard Details
              </h2>
            </header>
            <div className="p-3">
              <div className="overflow-x-auto">
                <table className="table-auto w-full">
                  <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                    <tr>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">Name</div>
                      </th>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">ContactNo</div>
                      </th>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">Lead Count</div>
                      </th>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">Meeting Count</div>
                      </th>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-center">Action</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-100">
                    {loading ? (
                      Array(10)
                        .fill()
                        .map((_, index) => (
                          <tr key={index}>
                            <td className="p-2 whitespace-nowrap">
                              <Skeleton width={150} />
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <Skeleton width={100} />
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <Skeleton width={50} />
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <Skeleton width={50} />
                            </td>
                            <td className="p-2 whitespace-nowrap text-center">
                              <Skeleton width={30} />
                            </td>
                          </tr>
                        ))
                    ) : (
                      currentTableData.map((item) => (
                        <tr key={item.id}>
                          <td className="p-2 whitespace-nowrap flex items-center gap-2">
                            <Avatar name={item?.UserName || "User"} />
                            <div>
                              {item.UserName} <p>{item.EmailID}</p>
                            </div>
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            {item.ContactNo}
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            {item.LeadCount || 0}
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            {item.MeetingCount || 0}
                          </td>
                          <td className="p-2 whitespace-nowrap text-center">
                            <button
                              className="text-blue-500"
                              onClick={() => handleLeadPreview(item)}
                            >
                              <FaEye />
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <Pagination
                  className="pagination-bar mt-4"
                  currentPage={currentPage}
                  totalCount={data.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </TitleCard>
  </div>
  );
};

export default DashBoardPages;
