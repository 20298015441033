// import React, { createContext, useState, useEffect, useContext, useCallback, useRef } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';

// const AuthContext = createContext();

// const INACTIVITY_LIMIT = 20 * 60 * 10000;
// export const AuthProvider = ({ children }) => {
//   const [userdata, setUserdata] = useState(() => {
//     const storedUserdata = sessionStorage.getItem('userdata');
//     return storedUserdata ? JSON.parse(storedUserdata) : null;
//   });

//   const [isAuthenticated, setIsAuthenticated] = useState(() => {
//     const storedTimestamp = sessionStorage.getItem('sessionTimestamp');
//     return storedTimestamp && Date.now() - parseInt(storedTimestamp, 10) < INACTIVITY_LIMIT;
//   });

//   const [logoutTimer, setLogoutTimer] = useState(null);
//   const timeoutRef = useRef(null);
//   // const navigate = useNavigate();
//   // const location = useLocation();

//   const startLogoutTimer = useCallback(() => {
//     if (timeoutRef.current) clearTimeout(timeoutRef.current);
//     timeoutRef.current = setTimeout(() => {
//       logout(); // Logout after inactivity period
//       // navigate('/2024'); // Redirect to a specific route
//     }, INACTIVITY_LIMIT);
//   }, [logoutTimer]);

//   const login = (data) => {
//     setUserdata(data);
//     setIsAuthenticated(true);
//     const timestamp = Date.now();
//     sessionStorage.setItem('isAuthenticated', 'true');
//     sessionStorage.setItem('sessionTimestamp', timestamp.toString());
//     sessionStorage.setItem('userdata', JSON.stringify(data));
//     startLogoutTimer(); // Start the logout timer on login
//   };

//   const logout = () => {
//     setIsAuthenticated(false);
//     setUserdata(null);
//     sessionStorage.removeItem('isAuthenticated');
//     sessionStorage.removeItem('sessionTimestamp');
//     sessionStorage.removeItem('userdata');
//     if (timeoutRef.current) clearTimeout(timeoutRef.current); // Clear the logout timer
//   };

//   // Handle user activity
//   const handleActivity = useCallback(() => {
//     const timestamp = Date.now();
//     sessionStorage.setItem('sessionTimestamp', timestamp.toString());
//     startLogoutTimer(); // Reset timer on user activity
//   }, [startLogoutTimer]);

//   useEffect(() => {
//     if (isAuthenticated) {
//       // Add event listeners for user activity
//       window.addEventListener('mousemove', handleActivity);
//       window.addEventListener('keydown', handleActivity);
//       window.addEventListener('click', handleActivity);
//       window.addEventListener('scroll', handleActivity);

//       // Start the timer when authenticated
//       startLogoutTimer();

//       return () => {
//         // Cleanup event listeners
//         window.removeEventListener('mousemove', handleActivity);
//         window.removeEventListener('keydown', handleActivity);
//         window.removeEventListener('click', handleActivity);
//         window.removeEventListener('scroll', handleActivity);
//         if (timeoutRef.current) clearTimeout(timeoutRef.current); // Clear the timer on cleanup
//       };
//     }
//   }, [isAuthenticated, handleActivity, startLogoutTimer]);

//   return (
//     <AuthContext.Provider value={{ userdata, isAuthenticated, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };




// import React, { createContext, useState, useEffect, useContext, useCallback, useRef } from 'react';
// import Cookies from 'js-cookie'; // Import js-cookie

// const AuthContext = createContext();

// const INACTIVITY_LIMIT = 20 * 60 * 1000; // Set to 20 minutes
// export const AuthProvider = ({ children }) => {
//   const [userdata, setUserdata] = useState(() => {
//     const storedUserdata = Cookies.get('userdata'); // Get userdata from cookies
//     return storedUserdata ? JSON.parse(storedUserdata) : null;
//   });

//   const [isAuthenticated, setIsAuthenticated] = useState(() => {
//     const storedTimestamp = Cookies.get('sessionTimestamp');
//     return storedTimestamp && Date.now() - parseInt(storedTimestamp, 10) < INACTIVITY_LIMIT;
//   });

//   const timeoutRef = useRef(null);

//   const startLogoutTimer = useCallback(() => {
//     if (timeoutRef.current) clearTimeout(timeoutRef.current);
//     timeoutRef.current = setTimeout(() => {
//       logout(); // Logout after inactivity period
//     }, INACTIVITY_LIMIT);
//   }, []);

//   const login = (data) => {
//     setUserdata(data);
//     setIsAuthenticated(true);
//     const timestamp = Date.now();
    
//     // Set  
//     Cookies.set('isAuthenticated', 'true', { expires: 30 }); // Expires in 30 days
//     Cookies.set('sessionTimestamp', timestamp.toString(), { expires: 30 });
//     Cookies.set('userdata', JSON.stringify(data), { expires: 30 });
    
//     startLogoutTimer(); // Start the logout timer on login
//   };

//   const logout = () => {  
//     setIsAuthenticated(false);
//     setUserdata(null);
    
//     // Remove cookies
//     Cookies.remove('isAuthenticated');
//     Cookies.remove('sessionTimestamp');
//     Cookies.remove('userdata');
    
//     if (timeoutRef.current) clearTimeout(timeoutRef.current); // Clear the logout timer
//   };

//   // Handle user activity
//   const handleActivity = useCallback(() => {
//     const timestamp = Date.now();
//     Cookies.set('sessionTimestamp', timestamp.toString(), { expires: 30 });
//     startLogoutTimer(); // Reset timer on user activity
//   }, [startLogoutTimer]);

//   useEffect(() => {
//     if (isAuthenticated) {
//       // Add event listeners for user activity
//       window.addEventListener('mousemove', handleActivity);
//       window.addEventListener('keydown', handleActivity);
//       window.addEventListener('click', handleActivity);
//       window.addEventListener('scroll', handleActivity);

//       // Start the timer when authenticated
//       startLogoutTimer();

//       return () => {
//         // Cleanup event listeners
//         window.removeEventListener('mousemove', handleActivity);
//         window.removeEventListener('keydown', handleActivity);
//         window.removeEventListener('click', handleActivity);
//         window.removeEventListener('scroll', handleActivity);
//         if (timeoutRef.current) clearTimeout(timeoutRef.current); // Clear the timer on cleanup
//       };
//     }
//   }, [isAuthenticated, handleActivity, startLogoutTimer]);

//   return (
//     <AuthContext.Provider value={{ userdata, isAuthenticated, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };

import React, { createContext, useState, useEffect, useContext, useCallback, useRef } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js'; // Import crypto-js

const AuthContext = createContext();

const SECRET_KEY = 'DFHFLGFHGOHFWU12345'; // Replace with a strong secret key
const INACTIVITY_LIMIT = 20 * 60 * 1000; // Set to 20 minutes

// Encryption and Decryption functions
const encrypt = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

const decrypt = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  
  // Ensure valid JSON format
  try {
    return decryptedData ? JSON.parse(decryptedData) : null;
  } catch (e) {
    console.error("Decryption failed or data is not valid JSON:", e);
    return null;
  }
};

export const AuthProvider = ({ children }) => {
  const [userdata, setUserdata] = useState(() => {
    const storedUserdata = Cookies.get('userdata');
    return storedUserdata ? decrypt(storedUserdata) : null;
  });

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const storedTimestamp = Cookies.get('sessionTimestamp');
    return storedTimestamp && Date.now() - parseInt(storedTimestamp, 10) < INACTIVITY_LIMIT;
  });

  const timeoutRef = useRef(null);

  const startLogoutTimer = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      logout(); // Logout after inactivity period
    }, INACTIVITY_LIMIT);
  }, []);

  const login = (data) => {
    setUserdata(data);
    setIsAuthenticated(true);
    const timestamp = Date.now();
    
    // Set encrypted cookies
    Cookies.set('isAuthenticated', 'true', { expires: 30 });
    Cookies.set('sessionTimestamp', timestamp.toString(), { expires: 30 });
    Cookies.set('userdata', encrypt(data), { expires: 30 });
    
    startLogoutTimer(); // Start the logout timer on login
  };

  const logout = () => {  
    setIsAuthenticated(false);
    setUserdata(null);
    
    // Remove cookies
    Cookies.remove('isAuthenticated');
    Cookies.remove('sessionTimestamp');
    Cookies.remove('userdata');
    
    if (timeoutRef.current) clearTimeout(timeoutRef.current); // Clear the logout timer
  };

  // // Handle user activity
  // const handleActivity = useCallback(() => {
  //   const timestamp = Date.now();
  //   Cookies.set('sessionTimestamp', timestamp.toString(), { expires: 30 });
  //   startLogoutTimer(); // Reset timer on user activity
  // }, [startLogoutTimer]);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     // Add event listeners for user activity
  //     window.addEventListener('mousemove', handleActivity);
  //     window.addEventListener('keydown', handleActivity);
  //     window.addEventListener('click', handleActivity);
  //     window.addEventListener('scroll', handleActivity);

  //     // Start the timer when authenticated
  //     startLogoutTimer();

  //     return () => {
  //       // Cleanup event listeners
  //       window.removeEventListener('mousemove', handleActivity);
  //       window.removeEventListener('keydown', handleActivity);
  //       window.removeEventListener('click', handleActivity);
  //       window.removeEventListener('scroll', handleActivity);
  //       if (timeoutRef.current) clearTimeout(timeoutRef.current); // Clear the timer on cleanup
  //     };
  //   }
  // }, [isAuthenticated, handleActivity, startLogoutTimer]);

  return (
    <AuthContext.Provider value={{ userdata, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};



// http://localhost:3001/