import React, { useState } from "react";
import OrganizationForm from "../MasterFrom/OrganizationForm";
import Organizationmain from "../Mastermains/Organizationmain";
import TitleCard from "../Card/TitleCard";
import { useUser } from "../Context/UserContext";
import { useAuth } from "../Context/AuthContext";
// const Organization = () => {
//   const { user } = useUser();
//   const { userdata } = useAuth();
//   const [showBilling, setShowBilling] = useState(true);
//   const [selectedRowData, setSelectedRowData] = useState(null);
//  console.log("Created_By",userdata)
//   const EmptData = {
//     SourceCountry: "",
//     City: "",
//     ClientCountry: "",
//     ClientType: "",
//     Address: "",
//     NationalDay: "",
//     OtherRelevantDays: "",
//     OtherRelevantDays1: "",
//     OtherRelevantDays2: "",
//     IsVisaSolutionsOutsourced: "",
//     NameOfServiceProvider: "",
//     CurrentContractValidityPeriod: "",
//     IsPossibilityToExtend: "",
//     ExtensionPeriod: "",
//     IsOutsourcedViaTender: "",
//     TenderingThrough: "",
//     Jurisdiction: "",
//     ApproxApplicantVolumes: "",
//     IsScopeOfferSkylaneSolutions: "",
//     TypeOfSolutionsOffered: "",
//     Created_By:userdata.UserID || "null", 
//   };

//   // To Chnage form
//   const changeForms = (rowData) => {
//     setSelectedRowData(rowData ? rowData : EmptData);
//     setShowBilling(!showBilling);
//   };

//   return (
//     <div>
//       <TitleCard title="Organization">
//         {showBilling ? (
//           <Organizationmain changeForms={changeForms} />
//         ) : (
//           <OrganizationForm
//             changeForms={changeForms}
//             selectedRowData={selectedRowData}
//           />
//         )}
//       </TitleCard>
//     </div>
//   );
// };
const Organization = () => {
  const { user } = useUser();
  const { userdata } = useAuth();
  const [showBilling, setShowBilling] = useState(true);
  const [selectedRowData, setSelectedRowData] = useState(null);
  
  console.log("Created_By", userdata);

  // Initialize EmptData inside the component
  const EmptData = {
    OrgID:0,
    SourceCountry: "",
    City: "",
    ClientCountry: "",
    ClientType: "",
    Address: "",
    NationalDay: "",
    OtherRelevantDays: "",
    OtherRelevantDays1: "",
    OtherRelevantDays2: "",
    IsVisaSolutionsOutsourced: "",
    NameOfServiceProvider: "",
    CurrentContractValidityPeriod: "",
    IsPossibilityToExtend: "",
    ExtensionPeriod: "",
    IsOutsourcedViaTender: "",
    TenderingThrough: "",
    Jurisdiction: "",
    ApproxApplicantVolumes: "",
    IsScopeOfferSkylaneSolutions: "",
    TypeOfSolutionsOffered: "",
    Created_By: userdata.UserID ? userdata.UserID : null, // Use null directly instead of "null"
  };

  const changeForms = (rowData) => {
    setSelectedRowData(rowData ? rowData : EmptData);
    console.log("Created_By EmptData", EmptData);
    setShowBilling(!showBilling);
  };

  return (
    <div>
      <TitleCard title="Organization">
        {showBilling ? (
          <Organizationmain changeForms={changeForms} />
        ) : (
          <OrganizationForm
            changeForms={changeForms}
            selectedRowData={selectedRowData}
          />
        )}
      </TitleCard>
    </div>
  );
};

export default Organization;

//export default Organization;
