import React, { useState } from "react";
import Usermastermain from "../Mastermains/Usermastermain";
import UsermasterForm from "../MasterFrom/UsermasterForm";
import TitleCard from "../Card/TitleCard";

const PageSize = 10;
const Usermaster = () => {
  const [showBilling, setShowBilling] = useState(true);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const EmptData = {
    UserID: "",
    UserCode: "",
    UserName: "",
    Password: "",
    ContactNo: "",
    EmailID: "",
    IsActive: "",
    Getempcode: "",
    CustomerContactID: "",
    BranchID: "",
    IsAdministrator: "",
    IsGroup: "",
    IsUser: "",
    GroupID: "",
    ProfileImagePath: "",
    UserCategory: "",
    CreatedBy: "",
    IsSuperUser:""
  };

  // To Chnage form
  const changeForms = (rowData) => {
    setSelectedRowData(rowData ? rowData : EmptData);
    setShowBilling(!showBilling);
  };

  return (

    <TitleCard  title="User Master">
      {showBilling ? (
        <Usermastermain changeForms={changeForms} />
      ) : (
        <UsermasterForm
          changeForms={changeForms}
          selectedRowData={selectedRowData}
        />
      )}
    </TitleCard>
  );
};

export default Usermaster;
