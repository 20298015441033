import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../Pages/Home";
import Usermaster from "../Pages/Usermaster";
import Branchmaster from "../Pages/BranchMaster";
import UserGroupMaster from "../Pages/UserMasterGroup";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import EntityMaster from "../Pages/Entitymaster";
import LeadPreview from "../Card/CardDetails";
import DashBoardPages from "../Pages/DashBoardPages";
import Seemorenotification from "./Seemorenotification";
import Organization from "../Pages/Organization"
const Dashboard = () => {
  const location = useLocation();

  useEffect(() => {
    // console.log("Location At DashBoard", location);
  }, [location]);

  return (
    <div className="flex-grow h-full flex flex-col bg-[#ebebeb] overflow-auto">
      <main className="flex-1  px-2 md:px-6">
        <Routes>
          <Route path="/Dashboard" element={<DashBoardPages />} />
          <Route path="/Organization/:id" element={<Organization/>} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Usermaster/:id" element={<Usermaster />} />
          <Route path="/BranchMaster" element={<Branchmaster />} />
          <Route path="/EntityMaster/:id" element={<EntityMaster />} />
          <Route path="/RoleMaster/:id" element={<UserGroupMaster />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/home/CardDetail" element={<LeadPreview />} />
          <Route path="/seemorenofication" element={<Seemorenotification/>}/>
        </Routes>
      </main>
    </div>
  );
};

export default Dashboard;
